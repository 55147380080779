const AuthProvider = {
	login: ( { username, password } ) => { /* ... */ },
	checkError: (error) => {
        const status = error.status;
        if ( 400 < status )
        {
            const sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) );
            var logoutURI = window.__logout_uri;

            if ( null !== sessionDetails )
                logoutURI = logoutURI.replace( window.__main_domain, sessionDetails.blog_url );

            localStorage.removeItem( 'authToken' );
            localStorage.removeItem( 'sessionDetails' );
            return Promise.reject( { redirectTo: logoutURI, logoutUser: false } );
        }
        return Promise.resolve( );
    },
	checkAuth: ( ) => localStorage.getItem( 'authToken' )
        ? Promise.resolve( )
        : Promise.reject( { redirectTo: window.__logout_uri, logoutUser: false } ),
	logout: ( ) => {
        var sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) ),
            logoutURI = window.__logout_uri;
        if ( null !== sessionDetails )
            logoutURI = logoutURI.replace( window.__main_domain, sessionDetails.blog_url );

        localStorage.removeItem( 'authToken' );
        localStorage.removeItem( 'sessionDetails' );

        window.location.replace( logoutURI );
        return Promise.resolve( );
    },
	getIdentity: ( ) => { 
        var sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) );
        if ( null !== sessionDetails )
            return Promise.resolve( sessionDetails.user );
        else
            return Promise.reject( { redirectTo: window.__logout_uri, logoutUser: false } );
    },
	getPermissions: (params) => {
        var sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) );
        if ( null !== sessionDetails )
            return Promise.resolve( sessionDetails.permissions );
        else
            return Promise.reject( { redirectTo: window.__logout_uri, logoutUser: false } );
    },
}

export default AuthProvider;
