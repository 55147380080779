import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Merchant'}</span>;
};

export const MerchantsCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm>
            <Typography variant="h6" fullWidth>Merchant Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Name" source="label" fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <SelectInput label="Merchant Type" source="namespace" fullWidth validate={required()} choices={[
                            { id: '\\Unity\\Payments\\Bambora', name: 'Bambora' },
                            { id: '\\Unity\\Payments\\Moneris', name: 'Moneris' },
                            { id: '\\Unity\\Payments\\Stripe', name: 'Stripe' }
                        ]} />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Default Fund Code" perPage={500} source="default_fundcode_id" reference="fundcodes" validate={required()}>
                        <AutocompleteInput optionText="fund_code" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="API / Public Key" source="api_key" fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="API Secret" source="api_secret" type="password" fullWidth validate={required()} />
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export default MerchantsCreator;
