import * as React from "react";

import { List, Datagrid, ReferenceField, TextField, DateField, NumberField, TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';

import PersonNameField from './PersonNameField';

import SendEmailReceiptButton from './SendEmailReceiptButton';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />
];

const DownloadReceiptButton = ( {record} ) => {
    const url = `${window.__rest_uri}/v2-issue-receipt/${record.id}?authToken=${localStorage.getItem( 'authToken' )}`;
    return ( <Button size="small" variant="outline" target="_blank" href={url}><PictureAsPdf />&nbsp;&nbsp;Download PDF</Button> );
};

export const ReceiptList = (props) => (
    <List {...props} filters={filters} bulkActionButtons={false} sort={{ field: 'receipted_at', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField label="Receipt #" source="receipt_number" />
            <DateField label="Date" source="receipted_at" options={{ timeZone: 'UTC', dateStyle: 'long' }} />
            <ReferenceField label="Donor" source="person_id" reference="people">
                <PersonNameField />
            </ReferenceField>
            <NumberField label="Amount" source="receipt_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
            <NumberField label="Non-Receipt" source="non_receipt_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
            <SendEmailReceiptButton />
            <DownloadReceiptButton />
        </Datagrid>
    </List>
);

export default ReceiptList;
