import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { useForm } from 'react-final-form';

const UnityEmailEditor = (props) => {
	const form = useForm( );
	const emailEditorRef = useRef(null);

	const onReady = () => {
		if ( props.hasOwnProperty( 'templateJson' ) && 'undefined' !== typeof props.templateJson && null !== props.templateJson )
		{
			const design = JSON.parse( props.templateJson );
			if ( design.hasOwnProperty( 'body' ) )
				emailEditorRef.current.editor.loadDesign( design );
		}

		fetch( 
			window.__rest_uri+'/v2-get-merge-tags', 
			{ headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
			)
				.then( ( data ) => data.json( ) )
				.then( function( data ) {
					if ( 'undefined' !== typeof data && null !== data && 0 < Object.keys( data ).length )
						emailEditorRef
							.current.editor
							.setMergeTags( data );
				} );

		emailEditorRef
			.current.editor
			.registerCallback( 'selectImage', function( data, done ) {
				var sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) ),
						blog_url = window.__main_domain;
				if ( null !== sessionDetails )
						blog_url = sessionDetails.blog_url;
				window.open( blog_url+'/wp-admin/upload.php', '_blank' );
			} );

		emailEditorRef
			.current.editor
			.addEventListener( 'design:updated', function( data ) {
				emailEditorRef
					.current.editor
					.exportHtml( ( data ) => {
						const { design, html } = data;

						form.change( 'email_json', JSON.stringify( design ) );
						form.change( 'email_html', JSON.stringify( html ) );
					} );
			} );
	}

	return (
		<React.Fragment>
			<EmailEditor 
				options={{ 
						'user': null,
						'appearance': {
							'panels': {
								'tools': {
									'dock': 'left'
								}
							}
						},
						'tools': {
							'image': {
								'properties': {
									'src': {
										'value': {
											'url': 'https://via.placeholder.com/500x100?text=IMAGE',
											'width': 500,
											'height': 100
										}
									}
								}
							}
						}
					}}
				ref={emailEditorRef}
				minHeight="800px"
				onReady={onReady} />
		</React.Fragment>
	);
};

export default UnityEmailEditor;
