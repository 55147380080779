import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    DateTimeInput,
    BooleanInput,
    FormDataConsumer,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.id ? `Editing ${record.hash}` : 'New Scanner'}</span>;
};

const optionRenderer = choice => ( choice && choice.last_name ? `${choice.last_name}, ${choice.first_name} (${choice.phone_number})` : '' );
const redirect_show = (basePath,id,data) => `/events/${data.event_id}/show/4`;

export const ScannerEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect_show}>
            <Typography variant="h6" fullWidth>Edit Scanner</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <TextInput label="Hash" source="hash" fullWidth disabled />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Event" source="event_id" reference="events" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Venue" source="venue_id" reference="venues" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Person (search by Name, Email, Phone, or Postal Code)" source="person_id" reference="people" validate={required()}>
                        <AutocompleteInput optionText={optionRenderer} />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => formData.event_id && <ReferenceInput fullWidth allowEmpty label="Ticket Type" source="ticket_id" reference="tickettypes" filter={{ event_id: formData.event_id }}>
                        <SelectInput optionText="label" />
                    </ReferenceInput> }
                    </FormDataConsumer>
                </Box>
                <FormDataConsumer>
                {({ formData, ...rest }) => formData.venue_id && <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth allowEmpty label="Room" source="room_id" reference="rooms" filter={{ venue_id: formData.venue_id }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box> }
                </FormDataConsumer>
            </Box>
            { false && <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="scanning_starts_at" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="scanning_ends_at" />
                </Box>
            </Box> }
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <BooleanInput fullWidth label="Scanner is Active" defaultValue={true} source="is_active" />
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default ScannerEditor;
