import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    BooleanInput,
    FormDataConsumer,
    SaveButton,
    Toolbar,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import CreateFundCode from './FundCodeCreator';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Ticket Type'}</span>;
};

const TicketTypeCreatorToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Save & Add Another Ticket Type"
            redirect={redirect_create}
            submitOnEnter={false}
        />
        <SaveButton
            label="Save & Finish"
            redirect={redirect_show}
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);

const redirect_show = (basePath,id,data) => `/events/${data.event_id}/show/1`;
const redirect_create = (basePath,id,data) => {
    let venue_id = data.venue_id;
    if ( 'undefined' === typeof venue_id )
        venue_id = null;

    return `/tickettypes/create?source={"event_id":${data.event_id},"venue_id":${venue_id},"label":"","description":"","value":0,"tangible_benefit":0,"fundcode_id":null,"is_active":true}`;
}

export const TicketTypeCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm toolbar={<TicketTypeCreatorToolbar />}>
            <Typography variant="h6" fullWidth>Ticket Type Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Event" source="event_id" reference="events" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Venue" source="venue_id" reference="venues" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <TextInput label="Ticket Name" source="label" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} />
            <FormDataConsumer>
            {({ formData, ...rest }) => formData.venue_id && <ReferenceInput fullWidth label="Assigned Venue Room" source="room_id" reference="rooms" filter={{ venue_id: formData.venue_id }} validate={required()}>
                <SelectInput optionText="name" />
            </ReferenceInput> }
            </FormDataConsumer>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="value" step="0.01" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="tangible_benefit" step="0.01" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Fund Code" perPage={500} source="fundcode_id" reference="fundcodes">
                        <AutocompleteInput optionText="fund_code" create={<CreateFundCode />}  />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <BooleanInput fullWidth label="Ticket is available for Purchase" defaultValue={true} source="is_active" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <BooleanInput fullWidth label="Ticket class can exceed room size" defaultValue={false} source="is_exceedable" />
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export default TicketTypeCreator;
