import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Send } from '@material-ui/icons';

import { issueTickets } from './Actions/IssueTickets';

class IssueTicketsButton extends Component {
    handleClick = () => {
        const { issueTickets } = this.props;
        const record = this.props.data[Object.keys(this.props.data)[0]].event;

        issueTickets( record.id, record );
    }

    render() {
        var record = false;
        if ( 0 < this.props.total )
            record = this.props.data[Object.keys(this.props.data)[0]].event;

        return <Button size="small" onClick={this.handleClick} disabled={false === record || true === record.tickets_issued}><Send />&nbsp;&nbsp;Issue Tickets</Button>;
    }
}

IssueTicketsButton.propTypes = {
    issueTickets: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default connect(null, { issueTickets })(IssueTicketsButton);
