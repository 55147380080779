import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import { Check } from '@material-ui/icons';

const SendAutoresponseButton = ( props ) => {
    const [ sent, setSent ] = React.useState( false );

    const handleClick = (e) => {
        e.preventDefault( );
        
        fetch( 
            window.__rest_uri+'/v2-send-email-autoresponse/'+props.record.id, 
            { method: 'PUT', headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
            )
                .then( ( data ) => data.json( ) )
                .then( function( data ) {
                    setSent( true );
                } );
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClick} disabled={sent}>{ !sent ? 'Resend' : <Check /> }</Button>
        </React.Fragment>
    );
}

SendAutoresponseButton.propTypes = {
    record: PropTypes.object
};

export default connect(null, { })(SendAutoresponseButton);
