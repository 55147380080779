import * as React from "react";

import {
    Show,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    TextField,
    ReferenceInput,
    AutocompleteInput,
    List,
    Datagrid,
    NumberField,
    TextInput,
    FunctionField,
    BooleanField,
    DateField,
    SelectInput,
    DateInput,
    ResourceContextProvider,
} from "react-admin";

import { Button } from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';

import SendAutoresponseButton from './SendAutoresponseButton';
import SendAutoresponsePreviewButton from './SendAutoresponsePreviewButton';
import DownloadAutoresponseHTMLButton from './DownloadAutoresponseHTMLButton';
import DownloadAutoresponsePDFButton from './DownloadAutoresponsePDFButton';

import renderPhoneNumber from "./Renderers/renderPhoneNumber";
import TextArrayField from "./TextArrayField";

import SendEmailReceiptButton from './SendEmailReceiptButton';

const donations_filters = [
    <ReferenceInput fullWidth label="Posts" source="post_id" reference="posts">
        <AutocompleteInput optionText="title" />
    </ReferenceInput>,
    <TextInput fullWidth label="Source URL" source="source_url" />,
    <ReferenceInput fullWidth label="Merchant" source="merchant_id" reference="merchants">
        <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <TextInput fullWidth label="Charge ID" source="merchant_charge_label" />,
    <SelectInput label="Card Type" source="card_type" choices={[
        { id: 'V', name: 'Visa' },
        { id: 'M', name: 'MasterCard' },
        { id: 'A', name: 'American Express' },
    ]} />,
    <TextInput fullWidth label="Card Last 4" source="card_last4" />,
    <ReferenceInput fullWidth label="Fund Code" source="fund_code" reference="fundcodes">
        <AutocompleteInput optionText="fund_code" optionValue="fund_code" />
    </ReferenceInput>,
    <TextInput fullWidth label="Email" source="email" />,
    <DateInput fullWidth label="Start Date" source="start_donation_datetime" />,
    <DateInput fullWidth label="End Date" source="end_donation_datetime" />,
];

const DonationsList = (props) => (
    <ResourceContextProvider resource="donations">
        <List {...props}
            resource="donations"
            basePath="donations"
            filters={donations_filters}
            filter={{ person_id: props.id }}
            syncWithLocation={false}
            sort={{ field: 'donation_datetime', order: 'DESC' }}
            bulkActionButtons={false}>
            <Datagrid>
                <NumberField label="Amount" source="donation_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
                <TextField label="Fund Code" source="fund_code" />
                <TextField label="Source URL" source="source_url" />
                <BooleanField label="Monthly" source="donation_recurring" />
                <DateField label="Date" source="donation_datetime" showTime options={{ dateStyle: 'long', timeStyle: 'short' }} />
                <SendAutoresponseButton />
                <SendAutoresponsePreviewButton />
                <DownloadAutoresponseHTMLButton />
                <DownloadAutoresponsePDFButton />
            </Datagrid>
        </List>
    </ResourceContextProvider>
);

const DownloadReceiptButton = ( {record} ) => {
    const url = `${window.__rest_uri}/v2-issue-receipt/${record.id}?authToken=${localStorage.getItem( 'authToken' )}`;
    return ( <Button size="small" variant="outline" target="_blank" href={url}><PictureAsPdf />&nbsp;&nbsp;Download PDF</Button> );
};

const ReceiptsList = (props) => (
    <ResourceContextProvider resource="receipts">
        <List {...props}
            resource="receipts"
            basePath="receipts"
            filter={{ person_id: props.id }}
            syncWithLocation={false}
            sort={{ field: 'receipted_at', order: 'DESC' }}
            bulkActionButtons={false}>
            <Datagrid>
                <TextField label="Receipt #" source="receipt_number" />
                <DateField label="Date" source="receipted_at" options={{ timeZone: 'UTC', dateStyle: 'long' }} />
                <NumberField label="Amount" source="receipt_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
                <NumberField label="Non-Receipt" source="non_receipt_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
                <SendEmailReceiptButton />
                <DownloadReceiptButton />
            </Datagrid>
        </List>
    </ResourceContextProvider>
);

export const PersonPanel = (props) => (
    <div>
        <Show {...props}>
            <SimpleShowLayout>
                <FunctionField
                    className="person-name"
                    label="Name"
                    render={(record) =>
                        `${record.first_name} ${record.last_name}`
                    }
                />
                <FunctionField
                    className="person-hash"
                    label="Hash"
                    render={(record) =>
                        `${record.hash}`
                    }
                />
                <TextField source="id" />
                <TextField source="email_address" />
                <TextField source="postal_code" />
                <FunctionField label="Phone" render={ record => renderPhoneNumber(record.phone_number) } />
                <TextArrayField source="tags" />
            </SimpleShowLayout>
        </Show>

        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Donations">
                    <DonationsList {...props} />
                </Tab>
                <Tab label="Receipts">
                    <ReceiptsList {...props} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    </div>
);

export default PersonPanel;