import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    DateTimeInput,
    ReferenceInput,
    SelectInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Event'}</span>;
};

export const EventEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>
            <Typography variant="h6" fullWidth>Event Details</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Language" source="language" reference="languages" defaultValue={"en"} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} validation={required()} />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Venue" source="venue_id" allowEmpty reference="venues">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="start_date" validation={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="end_date" />
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default EventEditor;
