import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    DateTimeInput,
    ReferenceInput,
    SelectInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Event'}</span>;
};

const redirect = (basePath,id,data) => {
    let venue_id = data.venue_id;
    if ( 'undefined' === typeof venue_id )
        venue_id = null;

    return `/tickettypes/create?source={"event_id":${data.id},"venue_id":${venue_id}}`;
}

export const EventsCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm redirect={redirect}>
            <Typography variant="h6" fullWidth>Event Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Language" source="language" reference="languages" defaultValue={"en"} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} validation={required()} />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Venue" source="venue_id" allowEmpty reference="venues">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="start_date" validation={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateTimeInput fullWidth source="end_date" />
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export default EventsCreator;
