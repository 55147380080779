import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Send } from '@material-ui/icons';

import { reissueTicket } from './Actions/ReissueTicket';

class ReissueTicketButton extends Component {
    handleClick = () => {
        const { reissueTicket } = this.props;
        const record = this.props.record;

        reissueTicket( record.id, record );
    }

    render() {
        var record = false;
        if ( this.props.record.hasOwnProperty( 'issued_at' ) )
            record = this.props.record;

        return <Button size="small" onClick={this.handleClick} disabled={false === record || null == record.issued_at}><Send />&nbsp;&nbsp;Reissue Ticket</Button>;
    }
}

ReissueTicketButton.propTypes = {
    reissueTicket: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default connect(null, { reissueTicket })(ReissueTicketButton);
