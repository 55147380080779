import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const TicketsSoldCountField = (props) => {
    const record = useRecordContext(props);
    const ticketsSoldLink = `/events/${record.event_id}/show/3?filter={"referral_person_id":${record.person_id}}`;
    return ( <Link to={ticketsSoldLink}>{record.ticket_count}</Link> );
}

TicketsSoldCountField.defaultProps = { label: 'Tickets Sold' };

export default TicketsSoldCountField;
