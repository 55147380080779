import * as React from "react";
import { linkToRecord, useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const PersonNameField = (props) => {
    const record = useRecordContext(props);
    const personEditPage = linkToRecord( '/people', record.id, 'edit' );
    return ( <Link to={personEditPage}>{record.last_name}, {record.first_name}</Link> );
}

PersonNameField.defaultProps = { label: 'Name' };

export default PersonNameField;
