import * as React from "react";

import { List, Datagrid, ReferenceField, TextField } from 'react-admin';

export const MerchantsList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <ReferenceField source="organization_id" reference="organizations">
                <TextField source="label" />
            </ReferenceField>
            <TextField label="Merchant Name" source="label" />
            <TextField label="Merchant Type" source="namespace" />
            <TextField label="API Key" source="api_key" />
        </Datagrid>
    </List>
);

export default MerchantsList;
