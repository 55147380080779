import * as React from "react";

import { List, Datagrid, ReferenceArrayField, SingleFieldList, TextField, FunctionField, TextInput, DateField, CloneButton } from 'react-admin';

import SendEmailsButton from './SendEmailsButton';
import SendApprovalRequestButton from './SendApprovalRequestButton';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />
];

const optionRenderer = choice => ( choice && choice.size ? `${choice.label} (${choice.size} People)` : '' );

export const EmailsList = (props) => (
    <List {...props} filters={filters} bulkActionButtons={false} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <DateField label="Send Date" source="send_at" showTime options={{ timeZone: 'America/Toronto', dateStyle: 'long', timeStyle: 'short' }} />
            <TextField label="Subject" source="subject" />
            <ReferenceArrayField label="Receipients" reference="groups" source="group_ids">
                <SingleFieldList>
                    <FunctionField render={optionRenderer} />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Exclusions" reference="groups" source="exclude_group_ids">
                <SingleFieldList>
                    <FunctionField render={optionRenderer} />
                </SingleFieldList>
            </ReferenceArrayField>
            <SendApprovalRequestButton />
            <SendEmailsButton />
            <CloneButton />
        </Datagrid>
    </List>
);

export default EmailsList;
