const renderPhoneNumber = ( number ) => {
	if ( 'undefined' === typeof number || null === number || 10 > number.length )
		return '';

	if ( 11 === number.length )
		number = number.substr( 1 );

	return '('+number.substr( 0, 3 )+') '+number.substr( 3, 3 )+'-'+number.substr( 6 );
};

export default renderPhoneNumber;
