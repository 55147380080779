import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    SelectInput,
    FormDataConsumer,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.name ? `Editing ${record.name}` : 'New Room'}</span>;
};

const redirect = (basePath,id,data) => `/venues/${data.venue_id}/show/1`;

export const RoomEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect}>
            <Typography variant="h6" fullWidth>Room Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="ID" source="id" fullWidth disabled />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Venue ID" source="venue_id" fullWidth disabled />
                </Box>
            </Box>
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <SelectInput source="layout" fullWidth choices={[
                        { id: 'free', name: 'Free' },
                        { id: 'seated', name: 'Seated' },
                        { id: 'tabled', name: 'Tabled' },
                    ]} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Overall Room Capacity" fullWidth source="capacity" defaultValue={0} />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => 'tabled' === formData.layout &&
                        <TextInput label="Table Size" fullWidth source="table_size" defaultValue={8} {...rest} />
                    }
                    </FormDataConsumer>
                </Box>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => 'tabled' === formData.layout &&
                        <TextInput label="Number of Tables" fullWidth source="table_number" defaultValue={10} {...rest} />
                    }
                    </FormDataConsumer>
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default RoomEditor;
