import Chip from '@material-ui/core/Chip'

const TextArrayField = ({ record, source }) => {
  const array = record[source]
  if ( typeof array === 'undefined' || array === null || array.length === 0 || typeof array.map !== 'function' ) {
    return <div />
  } else {
    return (
      <div>
        {array.map(item => <Chip className="margin-right" label={item} key={item}/>)}
      </div>
    )   
  }
}

TextArrayField.defaultProps = { addLabel: true }

export default TextArrayField;
