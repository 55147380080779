// in src/App.js
import * as React from "react";
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import queryString from 'query-string';

import { People, MailOutline, Event, LocationCity, Group, LocalAtm, AccountBalance, Drafts, Storage, Receipt } from '@material-ui/icons';

import UnityLayout from './Unity/Models/Layout/UnityLayout';

import AuthProvider from './Unity/AuthProvider';

import PeopleList from './Unity/Models/PeopleList';
import PersonCreator from './Unity/Models/PersonCreator';
import PersonEditor from './Unity/Models/PersonEditor';
import PersonPanel from './Unity/Models/PersonPanel';

import AutoResponsesList from './Unity/Models/AutoResponsesList';
import { AutoResponsesCreator, AutoResponsesEditor } from './Unity/Models/AutoResponsesForms';

import EventsList from './Unity/Models/EventsList';

import EventPanel from './Unity/Models/EventPanel';
import TicketCreator from './Unity/Models/TicketCreator';
import TicketEditor from './Unity/Models/TicketEditor';

import EventsCreator from './Unity/Models/EventsCreator';
import EventEditor from './Unity/Models/EventEditor';
import TicketTypeCreator from './Unity/Models/TicketTypeCreator';
import TicketTypeEditor from './Unity/Models/TicketTypeEditor';
import BundlerlinksCreator from './Unity/Models/BundlerlinksCreator';
import BundlerlinkEditor from './Unity/Models/BundlerlinkEditor';
import ScannerCreator from './Unity/Models/ScannerCreator';
import ScannerEditor from './Unity/Models/ScannerEditor';

import VenuesList from './Unity/Models/VenuesList';
import VenuePanel from './Unity/Models/VenuePanel';
import VenuesCreator from './Unity/Models/VenuesCreator';
import RoomsCreator from './Unity/Models/RoomsCreator';
import RoomEditor from './Unity/Models/RoomEditor';

import DonationsList from './Unity/Models/DonationsList';
import SubmissionsList from './Unity/Models/SubmissionsList';

import OrganizationsList from './Unity/Models/OrganizationsList';

import MerchantsList from './Unity/Models/MerchantsList';
import MerchantsCreator from './Unity/Models/MerchantsCreator';
import MerchantEditor from './Unity/Models/MerchantEditor';

import EmailsList from './Unity/Models/EmailsList';
import EmailCreator from './Unity/Models/EmailCreator';
import EmailEditor from './Unity/Models/EmailEditor';

import ReceiptList from './Unity/Models/ReceiptList';
import ReceiptCreator from './Unity/Models/ReceiptCreator';
import ReceiptEditor from './Unity/Models/ReceiptEditor';

class App extends React.Component
{
  constructor( props ) {
    super( props );

    const query = queryString.parse( window.location.search );
    if ( '' !== query.authToken && 'undefined' !== typeof query.authToken )
    {
      const authToken = query.authToken.toString( );
      localStorage.setItem( 'authToken', authToken );

      fetch( 
        window.__rest_uri+'/v2-get-user-session', 
        { headers: { 'Content-Type': 'application/json', 'authorization': 'Basic '+localStorage.getItem( 'authToken' ) } } 
        )
          .then( ( data ) => data.json( ) )
          .then( function( data ) {
            if ( data.hasOwnProperty( 'user' ) )
            {
              localStorage.setItem( 'sessionDetails', JSON.stringify( data ) );
              
              var new_location = window.location.href.replace( 'authToken='+authToken, '' );
              new_location = new_location.replace( '?&', '' );
              new_location = new_location.replace( '/?#/', '/#/' );

              window.location.replace( new_location );
            }
            else
              window.location.replace( window.__logout_uri );
          } );
    }

    var httpClient = (url, options = { }) => {
        if ( !options.headers )
            options.headers = new Headers( { Accept: 'application/json' } );
  
        options.headers.set( 'Authorization', 'Basic '+localStorage.getItem( 'authToken' ) );
    
        var session = JSON.parse( localStorage.getItem( 'sessionDetails' ) ),
            orgId = session.organization.id;

        if ( -1 !== url.indexOf( '?' ) )
          url = url+'&__org_id='+orgId;
        else
          url = url+'?__org_id='+orgId;

        return fetchUtils.fetchJson( url, options );
    };

    this.state = {
      dataProvider: jsonServerProvider( window.__rest_uri+'/v2', httpClient ),
      authProvider: AuthProvider
    }
  }

  render( ) {
    const features = window.__feature_flags.split( ',' );

    return ( <Admin layout={UnityLayout}
      dataProvider={this.state.dataProvider} 
      authProvider={this.state.authProvider}>
        <Resource icon={People}
          options={{ label: "People" }}
          name="people"
          list={PeopleList}
          create={PersonCreator}
          show={PersonPanel}
          edit={PersonEditor} />
        <Resource name="tickets"
          create={TicketCreator}
          edit={TicketEditor} />
        <Resource icon={MailOutline} 
          options={{ label: "Autoresponses" }}
          name="newautoresponses"
          list={ 0 <= features.indexOf( 'Autoresponses' ) ? AutoResponsesList : null }
          create={AutoResponsesCreator}
          edit={AutoResponsesEditor} />
        <Resource icon={ Drafts }
          options={{ label: "Campaigns" }}
          name="emails"
          list={ 0 <= features.indexOf( 'Campaigns' ) ? EmailsList : null }
          create={EmailCreator}
          edit={EmailEditor} />
        <Resource icon={Event} 
          options={{ label: "Events" }}
          name="events"
          list={ 0 <= features.indexOf( 'Events' ) ? EventsList : null }
          show={EventPanel}
          create={EventsCreator}
          edit={EventEditor} />
        <Resource name="tickettypes"
          create={TicketTypeCreator}
          edit={TicketTypeEditor} />
        <Resource name="bundlerlinks"
          create={BundlerlinksCreator}
          edit={BundlerlinkEditor} />
        <Resource name="scanners"
          create={ScannerCreator}
          edit={ScannerEditor} />
        <Resource icon={LocationCity}
          name="venues"
          list={ 0 <= features.indexOf( 'Events' ) ? VenuesList : null }
          show={VenuePanel}
          create={VenuesCreator} />
        <Resource name="rooms"
          create={RoomsCreator}
          edit={RoomEditor} />
        <Resource icon={Storage}
          name="submissions"
          list={ 0 <= features.indexOf( 'Patterns' ) ? SubmissionsList : null } />
        <Resource icon={LocalAtm}
          name="donations"
          list={ 0 <= features.indexOf( 'Patterns' ) ? DonationsList : null } />
        <Resource icon={Receipt}
          name="receipts"
          list={ 0 <= features.indexOf( 'Receipts' ) ? ReceiptList : null }
          show={ReceiptEditor}
          create={ReceiptCreator} />
        <Resource icon={Group} 
          name="organizations"
          list={OrganizationsList} />
        <Resource icon={AccountBalance}
          name="merchants"
          list={ 0 <= features.indexOf( 'Patterns' ) ? MerchantsList : null }
          create={MerchantsCreator}
          edit={MerchantEditor} />
        <Resource name="groups" />
        <Resource name="wordpress_blogs" />
        <Resource name="patterns" />
        <Resource name="fundcodes" />
        <Resource name="languages" />
        <Resource name="posts" />
        <Resource name="attendees" />
        <Resource name="bundlers" />
      </Admin> );
  }
} 

export default App;
