import * as React from "react";

import { Create, 
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { Typography, Box, InputAdornment } from '@material-ui/core';

const fakeBraceFormat = val => <span>&#123;&#123; {val} &#125;&#125;</span>

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h5">Custom Fields</Typography>
        <Typography variant="body2">
            You can use various custom fields in your email AutoResponses. Make sure to use handlebars (ie. {fakeBraceFormat( 'field' )}) to tell our system to replace the field with its actual value.
        </Typography>
        <Typography variant="h6">Submission</Typography>
        <Typography variant="body1" gutterBottom>
            {fakeBraceFormat( 'submission.first_name' )}<br />
            {fakeBraceFormat( 'submission.last_name' )}<br />
            {fakeBraceFormat( 'submission.email' )}<br />
            {fakeBraceFormat( 'submission.phone_cell' )}<br />
            {fakeBraceFormat( 'submission.created_at' )}
        </Typography>
        <Typography variant="h6">Intent</Typography>
        <Typography variant="body1" gutterBottom>
            {fakeBraceFormat( 'intent.amount' )}<br />
            {fakeBraceFormat( 'intent.created_at' )}
        </Typography>
    </div>
);

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New AutoReponse'}</span>;
};

export const AutoResponsesCreator = (props) => (
    <Create title={<Title />} aside={<Aside />} {...props}>
        <SimpleForm>
            <Typography variant="h6" fullWidth>Conditions</Typography>
            <Typography variant="body1" fullWidth gutterBottom>
                Set the conditions for when this AutoResponse will be triggered. You can include things like the Wordpress Site, Post, Pattern, and Language.
            </Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Pattern" source="pattern_id" reference="patterns" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Site" source="wordpress_blog_id" reference="wordpress_blogs" perPage={500} validate={required()}>
                        <SelectInput optionText="site_name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Post" source="post_id" allowEmpty reference="posts">
                        <AutocompleteInput optionText="title" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Langauge" source="language" reference="languages" defaultValue={"en"} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Typography variant="h6" fullWidth>Email Contents</Typography>
            <Typography variant="body1" fullWidth gutterBottom>
                You can set the actual contents of the email. Optionally, you can set a delay of up to 3 days.
            </Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth disabled source="from" defaultValue={ window.__default_email } validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="to" defaultValue="submission.email" validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="delay" defaultValue="0" validate={required()}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins</InputAdornment>
                        }} />
                </Box>
            </Box>
            <TextInput source="subject" fullWidth validate={required()} />
            <RichTextInput source="body" multiline={true} validation={required()} />
            <BooleanInput label="AutoResponse is Active" defaultValue={true} source="is_active" />
        </SimpleForm>
    </Create>
);

export const AutoResponsesEditor = (props) => (
    <Edit title={<Title />} aside={<Aside />} {...props}>
        <SimpleForm>
            <TextInput disabled variant="standard" hiddenLabel fullWidth source="id" />
            <Typography variant="h6" fullWidth>Conditions</Typography>
            <Typography variant="body1" fullWidth gutterBottom>
                Set the conditions for when this AutoResponse will be triggered. You can include things like the Wordpress Site, Post, Pattern, and Language.
            </Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Pattern" source="pattern_id" reference="patterns" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Site" source="wordpress_blog_id" reference="wordpress_blogs" perPage={500} validate={required()}>
                        <SelectInput optionText="site_name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Post" source="post_id" allowEmpty reference="posts">
                        <AutocompleteInput optionText="title" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Langauge" source="language" reference="languages" defaultValue={"en"} validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Typography variant="h6" fullWidth>Email Contents</Typography>
            <Typography variant="body1" fullWidth gutterBottom>
                You can set the actual contents of the email. Optionally, you can set a delay of up to 3 days.
            </Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth disabled source="from" defaultValue={ window.__default_email } validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="to" defaultValue="submission.email" validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="delay" defaultValue="0" validate={required()}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mins</InputAdornment>
                        }} />
                </Box>
            </Box>
            <TextInput source="subject" fullWidth validate={required()} />
            <RichTextInput source="body" multiline={true} validation={required()} />
            <BooleanInput label="AutoResponse is Active" defaultValue={true} source="is_active" />
        </SimpleForm>
    </Edit>
);

export default AutoResponsesCreator;
