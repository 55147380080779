import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const OnlineSalesCountField = (props) => {
    const record = useRecordContext(props);
    const onlineSalesLink = `/donations?filter={"source_url":"${record.source_url}"}`;
    return ( <Link to={onlineSalesLink}>{record.submission_count}</Link> );
}

OnlineSalesCountField.defaultProps = { label: 'Online Sales' };

export default OnlineSalesCountField;
