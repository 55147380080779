import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Beenhere } from '@material-ui/icons';

import { checkinTicket } from './Actions/CheckinTicket';

class CheckinTicketButton extends Component {
    handleClick = () => {
        const { checkinTicket } = this.props;
        const record = this.props.record;

        checkinTicket( record.id, record );
    }

    render() {
        var record = false;
        if ( this.props.record.hasOwnProperty( 'scanned_at' ) )
            record = this.props.record;

        return <Button size="small" onClick={this.handleClick} disabled={false === record || null != record.scanned_at}><Beenhere />&nbsp;&nbsp;Check-in Guest</Button>;
    }
}

CheckinTicketButton.propTypes = {
    checkinTicket: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default connect(null, { checkinTicket })(CheckinTicketButton);
