import * as React from "react";

import { List, Datagrid, ReferenceField, TextField, DateField, TextInput } from 'react-admin';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />
];

export const EventsList = (props) => (
    <List {...props} filters={filters} bulkActionButtons={false} sort={{ field: 'start_date', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <ReferenceField source="organization_id" reference="organizations">
                <TextField source="label" />
            </ReferenceField>
            <TextField label="Event Name" source="name" />
            <DateField label="Start Date" source="start_date" showTime options={{ timeZone: 'America/Toronto', dateStyle: 'long', timeStyle: 'short' }} />
            <TextField label="Description" source="description" />
        </Datagrid>
    </List>
);

export default EventsList;
