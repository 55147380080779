import * as React from "react";

import { useCreate, useCreateSuggestionContext } from 'react-admin';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField
} from '@material-ui/core';

const CreateFundCode = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext( );
    const [value, setValue] = React.useState( filter || '' );
    const [create] = useCreate( 'fundcodes' );

    const handleSubmit = (event) => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        'fund_code': value,
                        'source': 'HUMAN'
                    },
                },
            },
            {
                onSuccess: ({ data }) => {
                    setValue( '' );
                    onCreate( data );
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label="Fund Code"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreateFundCode;
