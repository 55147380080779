import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Venue'}</span>;
};

const redirect = (basePath,id,data) => `/rooms/create?source={"venue_id":${data.id}}`;

export const VenuesCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm redirect={redirect}>
            <Typography variant="h6" fullWidth>Venue Location</Typography>
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} />
            <TextInput source="address_line_one" fullWidth validate={required()} />
            <TextInput source="address_line_two" fullWidth />
            <TextInput source="address_line_three" fullWidth />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="city" validation={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="state" validation={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="postal_code" validation={required()} />
                </Box>
            </Box>
           <TextInput fullWidth label="Overall Venue Capacity Limit" source="max_capacity" defaultValue="0" />
        </SimpleForm>
    </Create>
);

export default VenuesCreator;
