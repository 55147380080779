import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Send, Check, Error} from '@material-ui/icons';

import { sendApprovalRequest } from './Actions/SendApprovalRequest';

class SendApprovalRequestButton extends Component {
    handleClick = () => {
        const { sendApprovalRequest } = this.props;
        const record = this.props.record;

        sendApprovalRequest( record.id, record );
    }

    render() {
        let record = false;
        if ( this.props.hasOwnProperty( 'record' ) )
            record = this.props.record;

        if ( null === record.approver_id || 0 === record.approver_id )
            return <Button size="small" disabled={true}><Error />&nbsp;&nbsp;Approver Needed</Button>;

        if ( true === record.is_sent )
        {
            if ( false === record.is_approved )
                return <Button size="small" disabled={true}><Check />&nbsp;&nbsp;Approval Request Sent</Button>;
            else
                return <Button size="small" disabled={true}><Check />&nbsp;&nbsp;Email Approved</Button>;
        }
        else
            return <Button size="small" onClick={this.handleClick} disabled={false === record || true === record.is_sent}><Send />&nbsp;&nbsp;Send Approval Request</Button>;
    }
}

SendApprovalRequestButton.propTypes = {
    sendApprovalRequest: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default connect(null, { sendApprovalRequest })(SendApprovalRequestButton);
