import * as React from "react";

import { List, Datagrid, TextField } from 'react-admin';

export const VenuesList = (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Venue Name" source="name" />
            <TextField label="Venue City" source="city" />
            <TextField label="Max. Capacity" source="max_capacity" />
        </Datagrid>
    </List>
);

export default VenuesList;
