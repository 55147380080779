import * as React from "react";

import { Create, 
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
    Toolbar,
    SaveButton,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import BundlerLink from './BundlerLink';

const Title = ({ record }) => {
    return <span>{record && record.id ? `Editing Bundler Link` : 'New Bundler Link'}</span>;
};

const optionRenderer = choice => ( choice && choice.last_name ? `${choice.last_name}, ${choice.first_name} (${choice.email_address})` : '' );

const redirectCreate = (basePath,id,data) => {
    return `/bundlerlinks/create?source={"event_id":${data.event_id},"organization_id":${data.organization_id},"wordpress_blog_id":${data.wordpress_blog_id},"post_id":${data.post_id},"person_id":null,"source_url":""}`;
}

const redirectShow = (basePath,id,data) => {
    let event_id = data.event_id;
    if ( 'undefined' === typeof event_id )
        event_id = null;

    return `/events/${event_id}/show/2`;
}

const BundlerlinksCreatorToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Save & Add Another Bundler Link"
            redirect={redirectCreate}
            submitOnEnter={false}
        />
        <SaveButton
            label="Save & Finish"
            redirect={redirectShow}
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);

export const BundlerlinksCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm toolbar={<BundlerlinksCreatorToolbar />}>
            <Typography variant="h6" fullWidth>Bundler Link Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Event" source="event_id" reference="events" disabled validate={required()}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Site" source="wordpress_blog_id" reference="wordpress_blogs" validate={required()}>
                        <SelectInput optionText="site_name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Post" source="post_id" reference="posts" validate={required()}>
                        <AutocompleteInput optionText="title" />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Person (search by Name, Email, Phone, or Postal Code)" source="person_id" reference="people" validate={required()}>
                        <AutocompleteInput optionText={optionRenderer} />
                    </ReferenceInput>
                </Box>
            </Box>
            <FormDataConsumer>
                {({ formData, ...rest }) => 0 < formData.post_id && 0 < formData.person_id && <BundlerLink record={formData} /> }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);

export default BundlerlinksCreator;
