import * as React from "react";

import { Show,
    TabbedShowLayout,
    Tab,
    Datagrid,
    ReferenceManyField,
    TextField,
    RichTextField,
    EditButton,
    DeleteButton } from 'react-admin';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const Title = ({ record }) => {
    return <span>{record ? `${record.name} ` : ''}</span>;
};

const RoomCreateButton = ( {record} ) => {
    const url = `#/rooms/create?source={"venue_id":${record.id}}`;
    return ( <Button variant="text" href={url}><AddIcon /> Create New Room</Button> );
};

const Venue = props => (
    <Show title={<Title />} {...props}>
        <TabbedShowLayout>
            <Tab label="location">
                <TextField source="name" />
                <RichTextField source="description" />
                <TextField source="address_line_one" />
                <TextField source="address_line_two" />
                <TextField source="address_line_three" />
                <TextField source="city" />
                <TextField source="state" />
                <TextField source="postal_code" />
            </Tab>
            <Tab label="rooms">
                <RoomCreateButton />
                <ReferenceManyField label="Rooms" reference="rooms" target="venue_id">
                    <Datagrid>
                        <TextField source="name" />
                        <TextField source="layout" />
                        <TextField source="capacity" />
                        <EditButton />
                        <DeleteButton redirect={false} />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
)

export default Venue;
