import * as React from "react";

import { List, Datagrid, ReferenceField, TextField, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';

const filters = [
    <TextInput label="Search by Subject" source="q" alwaysOn />,
    <ReferenceInput fullWidth label="Pattern Type" source="pattern_id" reference="patterns">
        <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <ReferenceInput fullWidth label="Post" source="post_id" reference="posts">
        <AutocompleteInput optionText="title" />
    </ReferenceInput>
];

export const AutoResponsesList = (props) => (
    <List {...props} filters={filters} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <ReferenceField source="organization_id" reference="organizations">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceField source="pattern_id" reference="patterns">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceField label="Site" source="wordpress_blog_id" reference="wordpress_blogs">
                <TextField source="site_name" />
            </ReferenceField>
            <ReferenceField label="Post" source="post_id" reference="posts">
                <TextField source="title" />
            </ReferenceField>
            <TextField label="Language" source="language" />
            <TextField label="From" source="from" />
            <TextField label="To" source="to" />
            <TextField label="Subject" source="subject" />
        </Datagrid>
    </List>
);

export default AutoResponsesList;
