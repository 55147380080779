import * as React from 'react';
import { AppBar } from 'react-admin';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		paddingRight: '1em',
	},
	spacer: {
		flex: 1,
	},
	button: {
		color: '#fff',
	}
});

const UnityAppBar = props => {
	const classes = useStyles();

	const wordpressRedirect = ( ) => {
		var sessionDetails = JSON.parse( localStorage.getItem( 'sessionDetails' ) ),
			blog_url = window.__rest_uri;
		if ( null !== sessionDetails )
			blog_url = sessionDetails.blog_url;

		window.location.replace( blog_url+'/wp-admin/' );
	};

	return (
		<AppBar {...props}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>
			<Typography
				variant="h6"
				color="inherit"
				>
				{ window.__client_name }
			</Typography>
			<span className={classes.spacer} />
			{ window.__show_wordpress && <Button 
				className={classes.button}
				onClick={wordpressRedirect}
				>
				Back to WordPress
			</Button> }
		</AppBar>
	);
};

export default UnityAppBar;
