import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Send, Check, Error } from '@material-ui/icons';

import { sendEmails } from './Actions/SendEmails';

class SendEmailsButton extends Component {
    handleClick = () => {
        const { sendEmails } = this.props;
        const record = this.props.record;

        sendEmails( record.id, record );
    }

    render() {
        let record = false;
        if ( this.props.hasOwnProperty( 'record' ) )
            record = this.props.record;

        if ( false === record.is_approved )
            return <Button size="small" disabled={true}><Error />&nbsp;&nbsp;Email Awaiting Approval</Button>;

        if ( true === record.is_sent )
            return <Button size="small" disabled={true}><Check />&nbsp;&nbsp;Email Sent</Button>;
        else
            return <Button size="small" onClick={this.handleClick} disabled={false === record || true === record.is_sent}><Send />&nbsp;&nbsp;Send Emails</Button>;
    }
}

SendEmailsButton.propTypes = {
    sendEmails: PropTypes.func.isRequired,
    record: PropTypes.object
};

export default connect(null, { sendEmails })(SendEmailsButton);
