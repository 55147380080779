import * as React from "react";

import { List, Datagrid, TextField } from 'react-admin';

export const OrganizationsList = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField label="ID" source="id" />
            <TextField label="Name" source="label" />
            <TextField label="Elections ID" source="external_id" />
        </Datagrid>
    </List>
);

export default OrganizationsList;
