import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

const UnsubscribePersonButton = ( props ) => {

    const handleClick = (e) => {
        e.preventDefault( );

        var session = JSON.parse( localStorage.getItem( 'sessionDetails' ) ),
            orgId = session.organization.id;
        
        window.open( window.__rest_uri+'/v2-unsubscribe-email?email='+props.record.email_address+'&organization_id='+orgId, '_blank' );
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="danger" onClick={handleClick}>Unsubscribe</Button>
        </React.Fragment>
    );
}

UnsubscribePersonButton.propTypes = {
    record: PropTypes.object
};

export default connect(null, { })(UnsubscribePersonButton);
