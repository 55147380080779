import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    BooleanInput,
    FormDataConsumer,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import CreatePersonDialog from './PersonCreateDialog';

const Title = ({ record }) => {
    return <span>{record && record.id ? `Editing ${record.id}` : 'New Ticket'}</span>;
};

const optionRenderer = choice => ( choice && choice.last_name ? `${choice.last_name}, ${choice.first_name} (${choice.email_address})` : <strong>ADD NEW PERSON</strong> );
const redirect_show = (basePath,id,data) => `/events/${data.event_id}/show/3`;

export const TicketEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect_show}>
            <Typography variant="h6" fullWidth>Edit Ticket</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Event" source="event_id" reference="events" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => formData.event_id && <ReferenceInput fullWidth label="Ticket Type" source="ticket_id" reference="tickettypes" filter={{ event_id: formData.event_id }} validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput> }
                    </FormDataConsumer>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Person (search by Name, Email, Phone, or Postal Code)" source="person_id" reference="people">
                        <AutocompleteInput optionText={optionRenderer} create={<CreatePersonDialog />} />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Bundler (search by Name, Email, Phone, or Postal Code)" source="referral_person_id" reference="people">
                        <AutocompleteInput optionText={optionRenderer} create={<CreatePersonDialog />} />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput source="seat_number" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="table_number" fullWidth />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Seating Requests" source="attributes.seating_requests" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Dietary Restrictions" source="attributes.diet_restrictions" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Ticket Paid By" source="attributes.paid_by" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <BooleanInput label="Is Not Attending?" source="attributes.is_attending" />
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default TicketEditor;
