import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.receipt_number ? `Editing ${record.receipt_number}` : 'New Receipt'}</span>;
};

const personOptionRenderer = choice => ( choice && choice.last_name ? `${choice.last_name}, ${choice.first_name} (${choice.email_address})` : '' );

export const ReceiptEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>
            <Typography variant="h6" fullWidth>Receipt Details</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <TextInput label="Receipt Number" source="receipt_number" fullWidth />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Organization" source="organization_id" reference="organizations" validate={required()}>
                        <SelectInput optionText="label" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Donor" source="person_id" reference="people" validate={required()}>
                        <AutocompleteInput optionText={personOptionRenderer} />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput source="type" defaultValue="ANNUAL" disabled fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="type_period" defaultValue="CALENDAR" disabled fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="receipt_type" defaultValue="CHARITY-TAX" disabled fullWidth validate={required()} />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput source="period" defaultValue={new Date().getFullYear()} fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="receipt_amount" fullWidth validate={required()} step="0.01" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Non-Receiptable Amount" source="non_receipt_amount" fullWidth step="0.01" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <DateInput fullWidth source="receipted_at" />
                </Box>
            </Box>
            <TextInput source="receipt_notes" fullWidth multiline={3} validation={required()} />
        </SimpleForm>
    </Edit>
);

export default ReceiptEditor;
