import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    BooleanInput,
    FormDataConsumer,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

import CreateFundCode from './FundCodeCreator';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Ticket Type'}</span>;
};

const redirect_show = (basePath,id,data) => `/events/${data.event_id}/show/1`;

export const TicketTypeEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm redirect={redirect_show}>
            <Typography variant="h6" fullWidth>Ticket Type Details</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Event" source="event_id" reference="events" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Venue" source="venue_id" reference="venues" disabled>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>
            </Box>
            <TextInput label="Ticket Name" source="label" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} />
            <FormDataConsumer>
            {({ formData, ...rest }) => formData.venue_id && <ReferenceInput fullWidth label="Assigned Venue Room" source="room_id" reference="rooms" filter={{ venue_id: formData.venue_id }} validate={required()}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            }
            </FormDataConsumer>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="value" validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth source="tangible_benefit" validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <ReferenceInput fullWidth label="Fund Code" perPage={500} source="fundcode_id" reference="fundcodes" validate={required()}>
                        <AutocompleteInput optionText="fund_code" create={<CreateFundCode />} />
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <BooleanInput fullWidth label="Ticket is available for Purchase" defaultValue={true} source="is_active" />
                </Box>
                <Box flex={1} mr="0.5em">
                    <BooleanInput fullWidth label="Ticket class can exceed room size" defaultValue={false} source="is_exceedable" />
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default TicketTypeEditor;
