import * as React from "react";

import { Create, 
    SimpleForm,
    TextInput,
    SelectInput,
    FormDataConsumer,
    SaveButton,
    Toolbar,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.subject ? `Editing ${record.subject}` : 'New Room'}</span>;
};

const RoomsCreatorToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Save & Add Another Room"
            redirect={redirect_create}
            submitOnEnter={false}
        />
        <SaveButton
            label="Save & Finish"
            redirect={redirect_show}
            submitOnEnter={false}
            variant="text"
        />
    </Toolbar>
);

const redirect_show = (basePath,id,data) => `/venues/${data.venue_id}/show/1`;
const redirect_create = (basePath,id,data) => `/rooms/create?source={"venue_id":${data.venue_id},"name":"","description":"","layout":"","capacity":0,"table_size":0,"table_number":0}`;

export const RoomsCreator = (props) => (
    <Create title={<Title />} {...props}>
        <SimpleForm toolbar={<RoomsCreatorToolbar />}>
            <Typography variant="h6" fullWidth>Room Details</Typography>
            <TextInput label="Venue ID" source="venue_id" fullWidth disabled />
            <TextInput source="name" fullWidth validate={required()} />
            <TextInput source="description" fullWidth multiline={1} />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <SelectInput source="layout" fullWidth choices={[
                        { id: 'free', name: 'Free' },
                        { id: 'seated', name: 'Seated' },
                        { id: 'tabled', name: 'Tabled' },
                    ]} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Overall Room Capacity" fullWidth source="capacity" defaultValue={0} />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => 'tabled' === formData.layout &&
                        <TextInput label="Table Size" fullWidth source="table_size" defaultValue={8} {...rest} />
                    }
                    </FormDataConsumer>
                </Box>
                <Box flex={1} mr="0.5em">
                    <FormDataConsumer>
                    {({ formData, ...rest }) => 'tabled' === formData.layout &&
                        <TextInput label="Number of Tables" fullWidth source="table_number" defaultValue={10} {...rest} />
                    }
                    </FormDataConsumer>
                </Box>
            </Box>
        </SimpleForm>
    </Create>
);

export default RoomsCreator;
