import * as React from 'react';
import { useGetMany, useGetOne, TextInput, required } from 'react-admin';

const BundlerLink = ( props ) => {
	const post = useGetMany( 'posts', [ props.record.post_id ] );
	const person = useGetOne( 'people', props.record.person_id );

	const onClick = ( e ) => {
		e.preventDefault( );
		if ( document.queryCommandSupported( 'copy' ) )
		{
			e.target.select( );
			document.execCommand( 'copy' );
		}
	};
	
	return ( <React.Fragment>
		{ console.log( post.data, post.data.length ) }
		<TextInput
			label="Bundler Link"
            source="source_url"
			defaultValue={ !!post.data.length && "undefined" !== typeof post.data[0] && `${post.data[0].url}?referral=${person.data.hash}`}
			InputProps={{
				readOnly: true,
			}}
			onClick={onClick}
            validate={required()}
			fullWidth
		/>
	</React.Fragment> )
}

export default BundlerLink;