import * as React from "react";

import { useCreate, useCreateSuggestionContext } from 'react-admin';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    Box
} from '@material-ui/core';

const CreatePersonDialog = () => {
    const { onCancel, onCreate } = useCreateSuggestionContext( );

    const [fname, setFname] = React.useState( '' );
    const [mname, setMname] = React.useState( '' );
    const [lname, setLname] = React.useState( '' );
    const [email, setEmail] = React.useState( '' );
    const [phone, setPhone] = React.useState( '' );
    const [postal, setPostal] = React.useState( '' );

    const [create] = useCreate( 'people' );

    const handleSubmit = (event) => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        'first_name': fname,
                        'middle_name': mname,
                        'last_name': lname,
                        'email_address': email,
                        'postal_code': postal,
                        'phone_number': phone
                    },
                },
            },
            {
                onSuccess: ({ data }) => {
                    setFname( '' );
                    setMname( '' );
                    setLname( '' );
                    setEmail( '' );
                    setPhone( '' );
                    setPostal( '' );

                    onCreate( data );
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box display="flex" fullWidth>
                        <Box flex={1} mr="0.5em">
                            <Typography variant="h6" fullWidth>New Person</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                label="First Name"
                                value={fname}
                                onChange={event => setFname(event.target.value)}
                                autoFocus
                                required
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                label="Middle Name"
                                value={mname}
                                onChange={event => setMname(event.target.value)}
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                label="Last Name"
                                value={lname}
                                onChange={event => setLname(event.target.value)}
                                required
                            />
                        </Box>
                    </Box>
                    <Box display="flex" fullWidth>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                label="Postal Code"
                                value={postal}
                                onChange={event => setPostal(event.target.value)}
                                required
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                type="email"
                                label="Email Address"
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                required
                            />
                        </Box>
                        <Box flex={1} mr="0.5em">
                            <TextField
                                type="tel"
                                label="Phone Number"
                                value={phone}
                                onChange={event => setPhone(event.target.value)}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreatePersonDialog;
